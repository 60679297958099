<template>
	<div class="login">
		<div class="login_back gradientColor">
			<van-icon v-if="show" @click="layout()" name="revoke" size="20px" color="white"
					  style="position: relative; top:10px; left:10px">学习中心
			</van-icon>
			<van-icon v-if="show" @click="questionTest()" name="tv-o" size="20px" color="white"
					  style="position: absolute; top:10px; right:10px">题库练习
			</van-icon>
			<div class="login_back_bottom gradientColor"></div>
		</div>
		<transition name="fade-left" appear translate>
			<component
				:is="curLogin"
				@toggleLogin="clickToggleLogin"
			></component>
		</transition>
	</div>
</template>

<script>
import idcardLogin from './components/idcard_login'
import phoneLogin from './components/phone_login'

export default {
	name: 'login',
	components: {idcardLogin, phoneLogin},
	props: {
		msg: String
	},
	data: () => ({
		curLogin: 'phoneLogin',
		show: true
	}),
	created() {
		if (location.host.startsWith('shandong.')) {
			this.show = false,
				localStorage.setItem('categoryId', this.$route.query.categoryId)
		}
	},
	mounted() {
		// if (location.host.startsWith('shuangpu.')
		// 	|| location.host.startsWith('wuhai.')
		// 	|| location.host.startsWith('antai.')
		// 	|| location.host.startsWith('shandong.')
		// 	|| (location.host.startsWith('yunnan.') && window.location.href.indexOf('APP01') === -1)
		// 	|| sessionStorage.getItem('lnjxjy') === '1') {
		// 	this.curLogin = 'idcardLogin';
		// }
		if (location.host.startsWith('hsy.')) {
			this.curLogin = 'phoneLogin';
		} else {
			this.curLogin = 'idcardLogin';
		}
	},
	methods: {
		clickToggleLogin(loginName) {
			this.curLogin = loginName
		},
		onSubmit(values) {
			console.log('submit', values, this.$router);
			this.$router.push({path: '/train'})
		},
		layout() {
			sessionStorage.clear();
			localStorage.clear();
			let keys = document.cookie.match(/[^ =;]+(?=)/g);
			if (keys) {
				for (let i = keys.length; i--;)
					document.cookie = keys[i] + '=0;domain=h5.xunmeizaixian.com;expires=' + new Date(0).toUTCString() + ';path=/';
			}
			// window.location.href = "/index.html#/login?appNo=" + sessionStorage.getItem('appno');
			this.$router.push({path: '/study-center'})
		},
		questionTest() {
			sessionStorage.clear();
			localStorage.clear();
			sessionStorage.setItem("toquestion", this.$route.query.appNo);
			// let keys = document.cookie.match(/[^ =;]+(?=)/g);
			// if(keys) {
			// 	for(let i = keys.length; i--;)
			// 		document.cookie = keys[i] + '=0;domain=h5.xunmeizaixian.com;expires=' + new Date(0).toUTCString() + ';path=/';
			// }
			window.location.href = "/#/viphome?appNo=APP02";
			// this.$router.push({ path: '/viphome'})
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.login {
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	position: relative;

	.login_back {
		position: relative;
		width: 100%;
		height: 195px;

		.login_back_bottom {
			width: 110%;
			height: 120px;
			border-radius: 0 0 50% 50%;
			position: absolute;
			left: -5%;
			bottom: -60px;
		}
	}
}
</style>
<style lang="less">
.login {
	.login_layout {
		width: 100%;
		position: absolute;
		left: 0;
		top: 85px;
		padding: 0 26px;

		.main {
			height: 370px;
			background-color: #FFF;
			border-radius: 10px;
			box-shadow: 0px 5px 7px 1px rgba(216, 216, 216, 0.25);
			position: relative;

			.main_portrait {
				position: absolute;
				width: 100%;
				top: -37px;

				> .box {
					margin: 0 auto;
					width: 74px;
					height: 74px;
					border: 2px solid #fff;
					border-radius: 50%;
					box-shadow: 0px 1px 2px 0px rgba(255, 135, 9, 0.35);
				}
			}

			.main_body {
				padding: 80px 26px 0;

				.title {
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #141827;
					margin-top: 0;
					margin-bottom: 17px;
				}

				.form {
					.van-field {
						padding: 0;
						background-color: #F5F5F5;
						border-radius: 20px;
						height: 40px;
						display: flex;
						align-items: center;
					}

					.van-field + .van-field {
						margin-top: 12px;
					}

					.sort-icon {
						width: 40px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
					}

					.other {
						text-align: right;

						.la-btn {
							margin-top: 12px;
							padding: 4px 13px;
						}
					}

					.btn {
						width: 100%;
						margin-top: 30px;
					}

					.explain {
						.text {
							color: #999;
						}

						> span {
							display: inline-block;
							vertical-align: middle;

							&.radio {
								width: 14px;
								height: 14px;
								border-radius: 50%;
								position: relative;
								text-align: center;

								> span {
									width: 8px;
									height: 8px;
									border-radius: 50%;
									position: absolute;
									top: 2px;
									left: 2px;
								}
							}

							& + span {
								width: calc(100% - 14px);
								font-size: 12px;
								padding-left: 6px;
							}
						}
					}
				}
			}
		}

		.other_login {
			padding-top: 27px;
			margin: 0 auto;

			.divider {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 20px;

				> div {
					font-size: 13px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #BFBFBF;

					&.text {
						padding: 0 5px;
					}

					&.line {
						width: 80px;
						height: 1px;
						background-color: #BFBFBF;
						position: relative;

						&:before {
							content: '';
							position: absolute;
							top: -2px;
							height: 5px;
							width: 5px;
							border-radius: 50%;
							background-color: #BFBFBF;
						}

						&.left:before {
							right: 0;
						}

						&.right:before {
							left: 0;
						}
					}
				}
			}

			.login_option {
				display: flex;
				align-items: center;
				justify-content: center;

				> div {
					text-align: center;

					img {
						width: 50px;
						height: 50px;
					}

					p {
						margin: 13px 0 0;
						padding: 0;
						color: #3E4A59;
						font-family: PingFang SC;
						opacity: 0.89;
						font-size: 12px;
					}
				}
			}
		}
	}
}
</style>
